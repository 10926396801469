import { render, staticRenderFns } from "./MitralTricuspidDialog.vue?vue&type=template&id=5bf85846&scoped=true"
import script from "./MitralTricuspidDialog.vue?vue&type=script&lang=js"
export * from "./MitralTricuspidDialog.vue?vue&type=script&lang=js"
import style0 from "./MitralTricuspidDialog.vue?vue&type=style&index=0&id=5bf85846&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bf85846",
  null
  
)

export default component.exports