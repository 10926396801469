<style scoped lang="less">
.valve-basic-information-muticards {
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 15px 10px 5px 10px;
  border-radius: 5px;
  position: relative;
  background-color: #fff !important;
  box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);

  .muticards {
    .el-card {
      margin: 10px 10px 10px 0;
    }

    .card_title {
      font-size: 16px;
      font-weight: 600;
    }

    .input-form-card {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .input-form-item {
      min-width: 30%;
    }

    .card-complication {
      .is-bordered {
        margin: 5px 10px 5px 0;
      }
    }
  }

  .valve-basic-label {
    margin: 15px;
    font-size: 16px;
    font-weight: bold;
  }

  .inner-title {
    position: absolute;
    top: -12px;
    left: 12px;
    background: #fff;
    padding: 0 10px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    padding-top: 5px;
  }

  .sty-edit {
    display: flex;
    align-items: center;
  }

  .save-button {
    padding-left: 10px;
  }

  .input-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .input_has_xldl {
      /deep/.el-input__inner {
        border: none;
      }
    }

    .input_has_unit {
      margin: 0;
    }

    .input-form-item {
      margin: 0.4rem 0.5rem;
      display: flex;
      align-items: center;

      .input-form-item-label {
        text-align: center;
        width: 120px;
      }
    }
  }

  .input-form-item {
    min-width: 28%;
    /* margin: 0.4rem 1.5rem; */
    margin: 0.4rem 0 0.4rem 0.5rem;
    display: flex;
    align-items: center;

    .input_has_unit {
      margin: 0;
    }

    .el-date-editor.el-input {
      width: 200px;
    }
  }

  @media screen and (max-width: 1180px) {
    .input-form-item {
      min-width: 40%;
    }
  }

  .input-form-item-label {
    width: 136px;
  }

  .input-form-item-label-length {
    width: 150px;
  }
}
</style>

<template>
  <div class="valve-basic-information-muticards">
    <div class="muticards" style="display: flex">
      <el-card style="flex: 1" :body-style="{ padding: '20px' }">
        <div slot="header" class="card_title">
          <span>术后诊断</span>
        </div>
        <div v-if="complicationSHZDList?.length > 0" class="card-complication">
          <el-radio-group
            v-if="!multiple"
            :disabled="isReadonly"
            v-model="aorticInfo.postDiagnosis"
            size="mini"
          >
            <el-radio
              v-for="(item, index) in complicationSHZDList"
              :key="index"
              :label="item.comboValue"
              border
            >
              {{ item.comboValue }}
            </el-radio>
          </el-radio-group>
          <el-checkbox-group
            v-else
            :disabled="isReadonly"
            v-model="aorticInfo.postDiagnosis"
            size="mini"
          >
            <el-checkbox
              v-for="(item, index) in complicationSHZDList"
              :key="index"
              :label="item.comboValue"
              border
            >
              {{ item.comboValue }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <el-empty v-else :image-size="70" description="暂无数据"></el-empty>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComplicationForm',
  components: {},
  props: {
    complicationData: {
      type: Object
    },
    isReadonly: {
      type: Boolean,
      default: () => false
    },
    complicationUrl: {
      // 并发症
      type: String,
      default: () => '/v1/webconsole/comboData/get/AO_postoperative_diagnosis'
    },
    finalDiagnosisUrl: {
      // 术后诊断
      type: String,
      default: () => '/v1/webconsole/comboData/get/AO_postoperative_diagnosis'
    }
  },
  data() {
    return {
      complicationSHZDList: [], // 术后诊断
      complicationList: [], // 并发症
      aorticInfo: {
        complication: [],
        postDiagnosis: null
      },
      multiple: false
    }
  },
  created() {},
  watch: {
    complicationData: {
      handler(val) {

        if (Object.keys(val).length > 0) {
          this.aorticInfo = {
            ...this.aorticInfo,
            postDiagnosis: this.multiple
              ? val?.postDiagnosis?.split('|') || []
              : val?.postDiagnosis,
            complication: val?.complication?.split('|') || []
          }
        }
      },
      immediate: true,
    },
    complicationUrl: {
      handler(val) {
        if (val) {
          // this.getComplication()
        }
      },
      immediate: true
    },
    finalDiagnosisUrl: {
      handler(val) {
        if (val) {
          this.getFinalDiagnosisUrl()
        }
      },
      immediate: true
    }
  },
  mounted() {},
  methods: {
    // 并发症
    getComplication() {
      if (!this.complicationUrl) return
      this.$api.get(this.complicationUrl).then(
        (res) => {
          if (res.data && res.data.data) {
            this.complicationList = res.data.data
          }
        },
        (err) => console.error(err)
      )
    },
    // 术后诊断
    getFinalDiagnosisUrl() {
      if (!this.finalDiagnosisUrl) return
      this.$api.get(this.finalDiagnosisUrl).then(
        (res) => {
          if (res.data && res.data.data) {
            this.multiple = res.data.data[0].comboType

            if (this.multiple) {
              this.aorticInfo.postDiagnosis = []
            }

            if (Object.keys(this.complicationData).length > 0) {
              this.aorticInfo = {
                ...this.aorticInfo,
                postDiagnosis: this.multiple
                  ? this.complicationData?.postDiagnosis?.split('|') || []
                  : this.complicationData?.postDiagnosis,
                complication: this.complicationData?.complication?.split('|') || []
              }
            }

            this.complicationSHZDList = res.data.data
          }
        },
        (err) => console.error(err)
      )
    }
  },
  computed: {}
}
</script>
