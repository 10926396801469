<template>
  <div class="get-undo-redo-main-content">
    <div class="get-undo-redo-main-content-head">
      <div class="template-select">
        <div class="template-select-text">
          <span>{{ templateData?.templateName }}</span>
        </div>
      </div>

      <div style="display: flex" v-show="!isReadOnly">
        <div class="redo-button">
          <el-button
            type="primary"
            @click="initData"
            class="common-button commonBtn"
            :disabled="isReadOnly"
          >
            获取
          </el-button>
        </div>
        <div class="redo-button">
          <el-button
            type="warning"
            @click="redo"
            class="common-button commonBtn"
            :disabled="isReadOnly"
          >
            清空
          </el-button>
        </div>
      </div>
    </div>
    <div class="get-undo-redo-main-content-body">
      <el-card>
        <cqt-input :isReadonly="isReadOnly" type="textarea" :rows="8" v-model="studyResult" />
      </el-card>
    </div>
  </div>
</template>

<script>
import CqtInput from '../../../components/cqt/CqtInput.vue'

export default {
  components: {
    CqtInput
  },
  props: {
    templateData: {
      type: Object,
      default: null
    },
    isReadOnly: {
      type: Boolean,
      require: true
    },
    surgeryInfo: {
      type: String,
      default: ''
    },
    operation: {
      type: String,
      default: () => ''
    }
  },
  created() {},
  computed: {},
  watch: {
    surgeryInfo: function (val) {
      this.studyResult = val
    }
  },
  data() {
    return {
      studyResult: undefined,
      status: 'result',
      operationId: undefined
    }
  },
  mounted() {
    this.operationId = this.$route.query.operationId
  },
  methods: {
    // 保存是在saveCoro或者pacemaker的study
    initData() {
      this.axios
        .get(`/v1/webconsole/study/operation/generate_result/${this.operationId}/${this.operation}`)
        .then((res) => {
          if (res.data.data && res.data.status == 200) {
            this.studyResult = res.data.data
          } else {
            this.studyResult = ''
            this.$message.error(res.data?.msg || '获取出错！')
          }
        })
    },
    redo() {
      this.studyResult = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.get-undo-redo-main-content {
  margin-top: 30px;
  margin-bottom: 30px;

  .get-undo-redo-main-content-head {
    height: 50px;
    display: flex;
    align-items: center;

    .template-select {
      height: 3rem;
      line-height: 3rem;
      display: flex;

      .el-select {
        width: 300px;
      }

      .template-select-text {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 16px;
        font-weight: bold;
      }
    }

    .undo-button {
      padding-left: 10px;
    }
  }
}
</style>
