var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.apiOptions.transfusionComponentUrl)?_c('div',{staticClass:"blood-box",style:(_vm.flexWrap === 'warp'
      ? 'display:flex;flex-wrap: wrap;flex-direction: row;'
      : 'display:flex;flex-direction: column;')},[_c('div',{staticClass:"input-form-item"},[_c('div',{staticClass:"input-form-item-label"},[_vm._v("输血")]),_c('cqt-select',{attrs:{"isReadonly":_vm.isReadonly,"activeUrl":"/v1/webconsole/comboData/get/Have_Not","value":_vm.aorticInfo.transfusion},on:{"update:value":function($event){return _vm.$set(_vm.aorticInfo, "transfusion", $event)}}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.aorticInfo.transfusion && _vm.aorticInfo.transfusion !== '无'),expression:"aorticInfo.transfusion && aorticInfo.transfusion !== '无'"}],staticClass:"input-form-item"},[_c('div',{staticClass:"input-form-item-label"},[_vm._v("成分")]),_c('cqt-select',{attrs:{"isReadonly":_vm.isReadonly,"activeUrl":"/v1/webconsole/comboData/get/AO_composition_blood_transfusion","value":_vm.aorticInfo.transfusionComponent},on:{"update:value":function($event){return _vm.$set(_vm.aorticInfo, "transfusionComponent", $event)}}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.aorticInfo.transfusion &&
      _vm.aorticInfo.transfusion !== '无' &&
      _vm.aorticInfo.transfusionComponent?.includes('红细胞')
    ),expression:"\n      aorticInfo.transfusion &&\n      aorticInfo.transfusion !== '无' &&\n      aorticInfo.transfusionComponent?.includes('红细胞')\n    "}],staticClass:"input-form-item"},[_c('div',{staticClass:"input-form-item-label"},[_vm._v("红细胞输血量：")]),_c('div',[_c('cqt-input',{staticClass:"input_has_unit",attrs:{"isReadonly":_vm.isReadonly,"placeholder":"请输入","type":"number","size":"mini","unit":"ml","min":"0","max":"1000"},on:{"input":function($event){return _vm.gettransfusionAmount('redBloodCellVolume')}},model:{value:(_vm.aorticInfo.redBloodCellVolume),callback:function ($$v) {_vm.$set(_vm.aorticInfo, "redBloodCellVolume", $$v)},expression:"aorticInfo.redBloodCellVolume"}},[_c('template',{slot:"append"},[_vm._v("ml")])],2)],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.aorticInfo.transfusion &&
      _vm.aorticInfo.transfusion !== '无' &&
      _vm.aorticInfo.transfusionComponent?.includes('血小板')
    ),expression:"\n      aorticInfo.transfusion &&\n      aorticInfo.transfusion !== '无' &&\n      aorticInfo.transfusionComponent?.includes('血小板')\n    "}],staticClass:"input-form-item"},[_c('div',{staticClass:"input-form-item-label"},[_vm._v("血小板输血量：")]),_c('div',[_c('cqt-input',{staticClass:"input_has_unit",attrs:{"isReadonly":_vm.isReadonly,"placeholder":"请输入","type":"number","size":"mini","unit":"ml","min":"0","max":"1000"},on:{"input":function($event){return _vm.gettransfusionAmount('plateletVolume')}},model:{value:(_vm.aorticInfo.plateletVolume),callback:function ($$v) {_vm.$set(_vm.aorticInfo, "plateletVolume", $$v)},expression:"aorticInfo.plateletVolume"}},[_c('template',{slot:"append"},[_vm._v("ml")])],2)],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.aorticInfo.transfusion &&
      _vm.aorticInfo.transfusion !== '无' &&
      _vm.aorticInfo.transfusionComponent?.includes('血浆')
    ),expression:"\n      aorticInfo.transfusion &&\n      aorticInfo.transfusion !== '无' &&\n      aorticInfo.transfusionComponent?.includes('血浆')\n    "}],staticClass:"input-form-item"},[_c('div',{staticClass:"input-form-item-label"},[_vm._v("血浆输血量：")]),_c('div',[_c('cqt-input',{staticClass:"input_has_unit",attrs:{"isReadonly":_vm.isReadonly,"placeholder":"请输入","type":"number","size":"mini","unit":"ml","min":"0","max":"1000"},on:{"input":function($event){return _vm.gettransfusionAmount('plasmaVolume')}},model:{value:(_vm.aorticInfo.plasmaVolume),callback:function ($$v) {_vm.$set(_vm.aorticInfo, "plasmaVolume", $$v)},expression:"aorticInfo.plasmaVolume"}},[_c('template',{slot:"append"},[_vm._v("ml")])],2)],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.aorticInfo.transfusion &&
      _vm.aorticInfo.transfusion !== '无' &&
      _vm.aorticInfo.transfusionComponent?.includes('全血')
    ),expression:"\n      aorticInfo.transfusion &&\n      aorticInfo.transfusion !== '无' &&\n      aorticInfo.transfusionComponent?.includes('全血')\n    "}],staticClass:"input-form-item"},[_c('div',{staticClass:"input-form-item-label"},[_vm._v("全血输血量：")]),_c('div',[_c('cqt-input',{staticClass:"input_has_unit",attrs:{"isReadonly":_vm.isReadonly,"placeholder":"请输入","type":"number","size":"mini","unit":"ml","min":"0","max":"1000"},on:{"input":function($event){return _vm.gettransfusionAmount('wholeBloodVolume')}},model:{value:(_vm.aorticInfo.wholeBloodVolume),callback:function ($$v) {_vm.$set(_vm.aorticInfo, "wholeBloodVolume", $$v)},expression:"aorticInfo.wholeBloodVolume"}},[_c('template',{slot:"append"},[_vm._v("ml")])],2)],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.aorticInfo.transfusion &&
      _vm.aorticInfo.transfusion !== '无' &&
      _vm.aorticInfo.transfusionComponent?.includes('其他')
    ),expression:"\n      aorticInfo.transfusion &&\n      aorticInfo.transfusion !== '无' &&\n      aorticInfo.transfusionComponent?.includes('其他')\n    "}],staticClass:"input-form-item"},[_c('div',{staticClass:"input-form-item-label"},[_vm._v("其他输血成分")]),_c('div',[_c('cqt-input',{staticClass:"input_has_unit",attrs:{"isReadonly":_vm.isReadonly,"placeholder":"请输入","type":"text","size":"mini"},model:{value:(_vm.aorticInfo.otherTransfusionComponent),callback:function ($$v) {_vm.$set(_vm.aorticInfo, "otherTransfusionComponent", $$v)},expression:"aorticInfo.otherTransfusionComponent"}})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.aorticInfo.transfusion &&
      _vm.aorticInfo.transfusion !== '无' &&
      _vm.aorticInfo.transfusionComponent?.includes('其他')
    ),expression:"\n      aorticInfo.transfusion &&\n      aorticInfo.transfusion !== '无' &&\n      aorticInfo.transfusionComponent?.includes('其他')\n    "}],staticClass:"input-form-item"},[_c('div',{staticClass:"input-form-item-label"},[_vm._v("其他成分输血量")]),_c('div',[_c('cqt-input',{staticClass:"input_has_unit",attrs:{"isReadonly":_vm.isReadonly,"placeholder":"请输入","type":"number","size":"mini","unit":"ml","min":"0","max":"1000"},on:{"input":function($event){return _vm.gettransfusionAmount('otherVolume')}},model:{value:(_vm.aorticInfo.otherVolume),callback:function ($$v) {_vm.$set(_vm.aorticInfo, "otherVolume", $$v)},expression:"aorticInfo.otherVolume"}},[_c('template',{slot:"append"},[_vm._v("ml")])],2)],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }