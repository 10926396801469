<template>
  <div class="info-table">
    <div style="display: flex">
      <span class="inner-title" style="margin: 0 8px">缘对缘修复</span>
    </div>
    <div style="margin: 10px 0 0 10px">
      <HeaderBtnUniversal
        :isFlex="true"
        :isLeftShow="true"
        :isRightShow="btnPosition == '左'"
        :classname="'valve-implants-number-head'"
        slotType="both"
        ref="HeaderBtnUniversal"
      >
        <template v-slot:left>
          <div v-show="!isReadonly">
            <el-button
              size="mini"
              type="primary"
              class="commonBtn"
              @click="mitralTricuspidInsert"
              :disabled="isReadonly || isShow"
            >
              新增
            </el-button>
            <el-button
              size="mini"
              type="warning"
              class="commonBtn"
              @click="mitralTricuspidModify"
              :disabled="isReadonly || isShow"
            >
              修改
            </el-button>
            <el-button
              size="mini"
              type="danger"
              class="commonBtn"
              @click="mitralTricuspidDelete"
              :disabled="isReadonly || isShow"
            >
              删除
            </el-button>
          </div>
        </template>
      </HeaderBtnUniversal>

      <el-table
        class="inner-table"
        highlight-current-row
        :data="mitralTricuspidTableData"
        :header-cell-style="{ padding: 0 }"
        height="130px"
        style="width: 99%"
        @row-click="_rowClick"
        @row-dblclick="_doubleModify"
        border
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column prop="clipModel" label="夹合器型号"></el-table-column>
        <el-table-column prop="aClipLocation" label="前瓣"></el-table-column>
        <el-table-column prop="aClipLocationOther" label="前瓣 其他"></el-table-column>
        <el-table-column prop="pClipLocation" label="后瓣"></el-table-column>
        <el-table-column prop="pClipLocationOther" label="后瓣 其他"></el-table-column>
        <el-table-column prop="success" label="是否成功"></el-table-column>
        <el-table-column prop="failReason" label="失败原因"></el-table-column>
        <el-table-column prop="conveyingSystemModel" label="输送系统型号"></el-table-column>
        <el-table-column prop="transvalvularDeviceModel" label="跨瓣器型号"></el-table-column>
        <el-table-column prop="systolicPressure" label="左房内收缩压（mmHg）"></el-table-column>
        <el-table-column prop="diastolicPressure" label="左房内舒张压（mmHg）"></el-table-column>
        <el-table-column prop="mean" label="左房内平均压（mmHg）"></el-table-column>
      </el-table>
      <mitral-tricuspid-dialog
        ref="mitralTricuspidDialog"
        :isReadonly="isReadonly"
        @save="_dialogSave"
      ></mitral-tricuspid-dialog>
    </div>
  </div>
</template>

<script>
import MitralTricuspidDialog from './MitralTricuspidDialog.vue'
import CqtSelect from '../../../../components/cqt/CqtSelect.vue'
import CqtInput from '../../../../components/cqt/CqtInput.vue'

import HeaderBtnUniversal from '@/views/Menu/components/HeaderBtnUniversal.vue'


export default {
  name: 'MitralTricuspidInformation',
  components: {
    MitralTricuspidDialog,
    CqtSelect,
    HeaderBtnUniversal,

    CqtInput
  },
  model: {
    event: 'change',
    prop: 'allDetail'
  },
  props: {
    isReadonly: {
      type: Boolean,
      default: () => false
    },
    repairIsShow: {
      type: Boolean,
      default: () => false
    },
    formId: {
      type: String,
      require: true
    },
    allDetail: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    allDetail: {
      handler(val) {
        if (val) {
          this._initInfoValue()
        }
      }
    }
  },
  data() {
    return {
      mitralTricuspidTableData: [],
      selectedInfo: null,
      tableKey: null,
      btnPosition: sessionStorage.getItem('btnPosition') || '左',

      isShow: false
    }
  },
  created() {},
  methods: {
    async _initInfoValue() {
      let url = `/v1/webconsole/eform/extendfields/${this.formId}/${this.allDetail.mitralTeer.id}`
      let formData = new FormData()
      formData.append('elementName', 'mitralTeer')
      this.$api.post(url, formData).then(
        ({ data: res }) => {
          if (res && res.data) {
            const arr = res.data.map((item) => {
              // 清空null值
              for (let i in item) {
                if (!item[i] || item[i] === 'null') {
                  item[i] = ''
                }
              }
              return item
            })
            this.mitralTricuspidTableData = arr
          }
        },
        () => {}
      )
    },
    mitralTricuspidInsert() {
      //   return this.$message.warning('请先完善二三尖瓣基本信息')
      this.$refs.mitralTricuspidDialog.Show()
    },
    mitralTricuspidModify() {
      if (!this.selectedInfo) return this.$message.warning('请先选择一条信息')
      if (this.selectedInfo) {
        this.$refs.mitralTricuspidDialog.Show(this.selectedInfo)
      }
    },
    mitralTricuspidDelete() {
      if (!this.selectedInfo) return this.$message.warning('请先选择一条信息')
      this.$confirm('是否删除该信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this._delete()
        })
        .catch((err) => {
          console.log(err)
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    _delete() {
      if (this.selectedInfo?.id) {
        const index = this.mitralTricuspidTableData.findIndex(
          (item) => item.id === this.selectedInfo.id
        )
        if (index !== -1) {
          this.mitralTricuspidTableData.splice(index, 1)
        }
      } else {
        const index = this.mitralTricuspidTableData.findIndex(
          (item) => item.ids === this.selectedInfo.ids
        )
        if (index !== -1) {
          this.mitralTricuspidTableData.splice(index, 1)
        }
      }
      this.selectedInfo = null
      this.$message({
        type: 'success',
        message: '删除成功!'
      })
    },
    _rowClick(row) {
      this.selectedInfo = null
      this.selectedInfo = row
    },
    _doubleModify(row) {
      this.selectedInfo = null
      this.selectedInfo = row
      this.$refs.mitralTricuspidDialog.Show(this.selectedInfo)
    },
    _dialogSave(val) {
      this.selectedInfo = null
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.mitralTricuspidTableData.length; i++) {
          const element = this.mitralTricuspidTableData[i]
          if (val.ids && val.ids === element.ids) {
            isId = false
            this.mitralTricuspidTableData[i] = val
            this.$set(this.mitralTricuspidTableData, i, this.mitralTricuspidTableData[i])
            break
          }
        }
        if (isId) {
          val.ids = Math.random(0, 1000) + Math.random(0, 1000)
          this.mitralTricuspidTableData.push(val)
        }
      } else {
        for (let i = 0; i < this.mitralTricuspidTableData.length; i++) {
          const element = this.mitralTricuspidTableData[i]
          if (val.id === element.id) {
            this.mitralTricuspidTableData[i] = val
            this.$set(this.mitralTricuspidTableData, i, this.mitralTricuspidTableData[i])
            break
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.info-table {
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 20px 15px;
  border-radius: 5px;
  position: relative;
  background-color: #fff !important;
  box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);
  /deep/.el-table .cell {
    white-space: normal;
  }
  /deep/.top-item-row .item-row span {
    margin: 0 0 0 5px;
  }
  button {
    margin-right: 0.5rem;
  }
}

.input-form-card {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.input-form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.input-form-item {
  min-width: 28%;
  margin: 0.4rem 1.5rem;
  display: flex;
  align-items: center;

  .input_has_unit {
    width: 200px;
    margin: 0;
  }

  .input_has_unit_text {
    margin: 0;
    width: 200px;
  }

  .el-date-editor.el-input {
    width: 200px;
  }
}

.input-form-item {
  min-width: 20%;
  margin: 0.4rem 0 0.4rem 0.5rem;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1180px) {
  .input-form-item {
    min-width: 20%;
  }
}

.input-form-item-label {
  width: 80px;
}

.inner-title {
  position: absolute;
  top: -12px;
  left: 12px;
  background: #fff;
  padding: 0 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding-top: 5px;
  display: flex;
  align-items: center;
}
.inner-table {
  margin-top: 10px;
  border: 1px solid black;
  height: 200px;
  position: relative;
  left: 5px;
}
.inner-tabs {
  margin: 10px 10px;
  height: 250px;
}
.item-inline {
  margin: 15px 25px;
  display: flex;
  justify-content: flex-end;
}
.item-inline > span {
  height: 25px;
  line-height: 25px;
  margin-right: 10px;
}
.item-column {
  display: flex;
  flex-direction: column;
}
</style>
